import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  FormRow,
  eventService,
  loggedIn,
} from "@commonninja/commonninja-styleguide-react";
import {
  H3,
  User,
} from "@commonninja/commonninja-styleguide-react/lib/esm/components/internal";
import PasswordStrengthBar from "react-password-strength-bar";

import { IAuthProps } from "./auth.types";
import { AuthLayout } from "./authLayout.comp";
import { authService } from "./auth.service";
import { AuthForm } from "./authForm.comp";
import { clearRedirectUrl } from "../../helpers/helpers";
import { SocialLogin } from "./socialLogin.comp";
import { CommonNinjaWidget } from "commonninja-react";
import { useDispatch, useSelector } from "react-redux";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import posthog from 'posthog-js';

// declare let gtag: Function;
// declare let ga: Function;

export const Signup = ({ embedMode, isDeveloper = false }: IAuthProps) => {
  const { search } = useLocation();
  const { redirectUrl, serviceName } = queryString.parse(search);
  const firstInput: any = useRef();
  const passwordInput: any = useRef();
  const { user } = useSelector((state: any) => ({
    user: state.user,
  }));
  const dispatch = useDispatch();
  const [postSubmission, setPostSubmission] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [agreed, setAgreed] = useState<boolean>(true);
  const [formState, setFormState] = useState<any>({
    username: "",
    fullName: "",
    password: "",
  });

  function formFieldChanged(propName: string, value: any) {
    setFormState({
      ...formState,
      [propName]: propName === "username" ? value.toLowerCase() : value,
    });
  }

  const cb = () => {
    window.setTimeout(function () {
      // Post message to parent
      if (embedMode) {
        window.parent.postMessage(
          {
            type: "COMMONNINJA_AUTH_SUCCESS",
            json: {},
          },
          "*"
        );

        return;
      }

      // Redirect to redirectUrl based on query string
      if (redirectUrl && clearRedirectUrl(redirectUrl as string)) {
        window.location.href = decodeURIComponent(redirectUrl as string);
        return;
      }

      // Fallback to user dashboard
      window.location.href = "/dashboard";
    }, 5500);
  };

  function postSignupRedirect(newUser: boolean = false) {
    setPostSubmission(true);
    setIsNew(newUser);

    try {
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: "signup",
        ecommerce: {},
      });
      // @ts-ignore
      window.fbq?.("track", "CompleteRegistration");

      // @ts-ignore
      window.lintrk?.("track", { conversion_id: 18793505 });

      // Report to posthog
      if (embedMode) {
        posthog.capture("signup_completed");
      }
    } catch (e) {}

    // Ads conversions event
    // gtag("event", "conversion", {
    //   send_to: "AW-664136719/cgFnCJWH3MUBEI_Y17wC",
    // });

    // GA Signup event
    // ga("send", "event", "Clicks", "sign up completed");

    eventService.reportMixpanelEvent("Signup success", {
      serviceName,
      authenticated: true,
      embedMode,
    });

    if (newUser) {
      (window as any)["CommonNinjaPostSignupCallback"] = cb;
    } else {
      cb();
    }
  }

  function renderPostSubmissionMessage() {
    if (isNew) {
      return (
        <>
          <CommonNinjaWidget
            widgetId="2518b460-034d-48d5-9224-729fd0db342b"
            widgetProps={encodeURIComponent(
              JSON.stringify({
                "a3b32af6-a92e-4102-a7dd-cce497e215d9":
                  formState.username || user.email || "",
                "10b8a8cf-b73f-4e66-a655-92e8779bd3b6": user.guid,
              })
            )}
          />
          <User
            user={user}
            componentType=""
            serviceName="commonninja"
            noUI={true}
            postLoginCallback={(localUser: any) => {
              dispatch(loggedIn(localUser));
            }}
          />
        </>
      );
    }

    return (
      <>
        <H3>Check your inbox</H3>
        <p className="body-1 post-message">
          A confirmation link has been sent to your email address. Please follow
          the instructions in order to proceed.
        </p>
        <div className="buttons-wrapper center">
          <NavLink
            className="primary"
            to={`${embedMode ? "/embed" : ""}/login${search ? search : ""}`}
          >
            Back to Login Page
          </NavLink>
        </div>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        {embedMode ? (
          <EmbedSignupTitle />
        ) : (
          <>
            <H3>Sign Up</H3>
            <p>Create your account to get started</p>
          </>
        )}
        <SocialLogin
          successCallback={postSignupRedirect}
          clickCallback={() => {
            eventService.reportMixpanelEvent("Click Google signup", {
              serviceName,
              authenticated: false,
              embedMode,
            });
          }}
          isDeveloper={isDeveloper}
        />
        <div className="or">Or</div>
        <AuthForm
          name="signup"
          mainButtonText="Sign up"
          successCallback={() => postSignupRedirect(true)}
          embedMode={embedMode}
          buttonDisabled={!agreed}
          footerComp={
            <div className="center">
              <NavLink
                to={`${embedMode ? "/embed" : ""}${
                  isDeveloper ? "/developer" : ""
                }/login${search ? search : ""}`}
                className="primary"
              >
                I already have an account
              </NavLink>
            </div>
          }
          authRequest={(captchaToken) => {
            eventService.reportMixpanelEvent("Click signup", {
              serviceName,
              authenticated: false,
              embedMode,
            });
            return authService.signup(formState, captchaToken);
          }}
          firstInput={firstInput}
          invalidMessage={!passwordValid ? "Password is invalid" : ""}
        >
          <FormRow flow="column">
            <input
              type="email"
              placeholder="Enter email address"
              ref={firstInput}
              value={formState.username}
              onChange={(e) => formFieldChanged("username", e.target.value)}
              minLength={4}
              required
            />
          </FormRow>
          <FormRow flow="column">
            <input
              type="text"
              value={formState.fullName}
              placeholder="Enter full name"
              onChange={(e) => formFieldChanged("fullName", e.target.value)}
              minLength={3}
              maxLength={120}
              required
            />
          </FormRow>
          <FormRow flow="column">
            {/* <label>Password</label> */}
            <input
              type="password"
              value={formState.password}
              placeholder="Enter password"
              onChange={(e) => formFieldChanged("password", e.target.value)}
              minLength={5}
              maxLength={60}
              required
              autoComplete="off"
            />
            {formState.password && (
              <PasswordStrengthBar
                ref={passwordInput}
                password={formState.password}
                minLength={5}
                onChangeScore={(nextScore) => {
                  setPasswordValid(nextScore > 0);
                }}
                style={{ width: "100%", marginTop: 5 }}
              />
            )}
          </FormRow>
          <FormRow className="terms">
            <input
              checked={agreed}
              onChange={(e) => {
                setAgreed(!agreed);
              }}
              id="agree-to-terms"
              type={"checkbox"}
              required
            />
            <label htmlFor="agree-to-terms">
              I agree to the{" "}
              <a
                href="https://www.commoninja.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
            </label>
          </FormRow>
        </AuthForm>
      </>
    );
  }

  useEffect(() => {
    document.title = `Sign up | Common Ninja`;

    eventService.reportMixpanelEvent(`Enter Signup`, {
      authenticated: false,
    });

    window.setTimeout(function () {
      window.parent.postMessage(
        {
          type: "COMMONNINJA_DASHBOARD_IFRAME_UPDATE",
          height: (document.querySelector("#main-content") as HTMLBodyElement)
            .clientHeight,
        },
        "*"
      );
    }, 100);

    function messageHandler(event: MessageEvent) {
      if (event.data && event.data.type === "cn-form-submitted") {
        cb();
      }
    }

    // Add the event listener
    window.addEventListener("message", messageHandler);

    // Cleanup
    return () => {
      window.removeEventListener("message", messageHandler);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout
      isDeveloper={isDeveloper}
      embedMode={embedMode}
      teaser="Break Your Website Walls for Limitless Possibilities"
    >
      {postSubmission ? renderPostSubmissionMessage() : renderForm()}
    </AuthLayout>
  );
};

const EmbedSignupTitle = () => {
  const variant = useFeatureFlagVariantKey("editor-signup-page-conversion");

  if (variant === "create-account-title") {
    return (
      <p style={{ marginBottom: 20 }}>Create an account to save your widget</p>
    );
  }

  return (
    <>
      <H3>Sign Up</H3>
      <p>Create your account to get started</p>
    </>
  );
};
