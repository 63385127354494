import {
  Button,
  HttpService,
  Popup,
  eventService,
  plansService,
} from "@commonninja/commonninja-styleguide-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IPluginListingNew } from "../../../services/appList.service";
import { toast } from "react-toastify";
import { IAppPlan } from "../../developer/developer.types";
import { CommonNinjaWidget } from "commonninja-react";
import { IPricingData } from "../newPricing.comp";
import { PricingTable } from "../pricingTable/pricingTable.comp";
import posthog from 'posthog-js';

const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

export const CheckoutButton = ({
  embedMode,
  websiteMode,
  apps,
  serviceName,
  pricingData,
  plan,
  onLoading,
  activeVariantId,
}: {
  embedMode: boolean;
  websiteMode: boolean;
  apps: IPluginListingNew[];
  serviceName: string;
  plan: IAppPlan;
  activeVariantId: number;
  pricingData: IPricingData;
  onLoading: (loading: boolean, type?: "checkout" | "update") => void;
}) => {
  // For non registered users
  const { userDetails } = pricingData;
  const activeApp = apps.find((a) => a.serviceName === serviceName);
  const history = useHistory();
  const { user } = useSelector((state: any) => ({ user: state.user }));
  const {
    params: { vendor },
  } = useRouteMatch() as any;
  const [showCancellationPopup, setShowCancellationPopup] =
    useState<boolean>(false);
  const [showSwitchPlanPopup, setShowSwitchPlanPopup] =
    useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [cannotDowngradePopupError, setCannotDowngradePopupError] =
    useState<string>("");
  const handlingCancellation = useRef<boolean>(false);

  async function redirectToCheckoutUrl(
    pricingVariantId: string,
    platform: string,
    price: number,
    localServiceName: string
  ) {
    try {
      onLoading(true, "checkout");
      toast.dismiss();

      let componentType = apps.filter(
        (a) => a.serviceName === localServiceName
      )?.[0]?.componentType;

      if (serviceName === "bundles") {
        componentType = "bundle";
      } else if (serviceName === "minibundles") {
        componentType = "mini_bundles";
      } else if (serviceName === "widgetsbundle") {
        componentType = "widgets_bundle";
      }

      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/getCheckoutUrl/${componentType}?${client.queryParams}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pricingVariantId, platform }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot get checkout url.");
      }

      const checkoutUrl = result.data; // Returns the checkout url
      const sessionId = checkoutUrl.split("?")?.[0]?.split("/")?.pop() || "";
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      let redirectUrl = `${baseUrl}/embed/${
        websiteMode ? "website/" : ""
      }checkout-success/${localServiceName}?sessionId=${sessionId}&variantId=${pricingVariantId}`; // Post successful checkout url

      if (platform === "shopify") {
        const parentUrl = new URL(window.location.href).searchParams.get(
          "origUrl"
        );
        if (parentUrl) {
          redirectUrl = decodeURIComponent(parentUrl);
        }
      }

      let finalUrl = `${checkoutUrl}${
        checkoutUrl.includes("?") ? "&" : "?"
      }redirectUrl=${encodeURIComponent(redirectUrl)}`;

      // For Rewardy referrals program
      const referral = (window as any)?.Rewardful?.referral;
      if (referral) {
        finalUrl += `&referral=${referral}`;
      }

      eventService.reportMixpanelEvent(`Checkout Start`, {
        authenticated: user.isAuthenticated,
        serviceName: localServiceName,
      });

      if ((websiteMode || vendor) && window.top) {
        window.top.location.href = finalUrl;
      } else {
        window.location.href = finalUrl;
      }
    } catch (e) {
      toast.error((e as Error).message);
      onLoading(false);
    }
  }

  async function updateSubscription(
    subscriptionId: string,
    pricingVariantId: string
  ) {
    try {
      setButtonLoading(true);
      toast.dismiss();

      let componentType = apps.filter((a) => a.serviceName === serviceName)?.[0]
        ?.componentType;

      if (serviceName === "bundles") {
        componentType = "bundle";
      } else if (serviceName === "minibundles") {
        componentType = "mini_bundles";
      } else if (serviceName === "widgetsbundle") {
        componentType = "widgets_bundle";
      }

      const client = new HttpService();

      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/updateSubscription/${componentType}?${client.queryParams}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId,
            planId: plan.planId,
            pricingVariantId,
          }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot update subscription plan.");
      }

      toast.success("Your subscription has been updated successfully");

      setShowSwitchPlanPopup(false);
      setButtonLoading(false);
      onLoading(true, "update");

      window.setTimeout(() => {
        onLoading(false);
      }, 800);

      // Reload pricing plans
      // await loadPricingPlans();
    } catch (e) {
      setButtonLoading(false);
      const message = (e as Error).message;
      if (message.includes("less widgets")) {
        setCannotDowngradePopupError(message);
      } else {
        toast.error((e as Error).message);
      }
    }
  }

  async function cancelSubscription(subscriptionId: string) {
    try {
      onLoading(true, "update");
      toast.dismiss();

      let componentType = apps.filter((a) => a.serviceName === serviceName)?.[0]
        ?.componentType;

      if (serviceName === "bundles") {
        componentType = "bundle";
      } else if (serviceName === "minibundles") {
        componentType = "mini_bundles";
      } else if (serviceName === "widgetsbundle") {
        componentType = "widgets_bundle";
      }

      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/cancelSubscription/${componentType}?${client.queryParams}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subscriptionId }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot cancel subscription.");
      }

      toast.success("Your subscription has been cancelled successfully");
      onLoading(false);
      return true;
      // Reload pricing plans
      // await loadPricingPlans();
    } catch (e) {
      toast.error((e as Error).message);
      onLoading(false);
      return false;
    }
  }

  function renderSwitchPlanPopup(
    nextVariantId: string,
    subscriptionId: string
  ) {
    if (!showSwitchPlanPopup) {
      return <></>;
    }

    const currentPlan = pricingData.plans.find(
      (p) => p.planId === userDetails.plan?.planId
    );
    const currentVariant = currentPlan?.pricingVariants?.findIndex(
      (v) => v.id === userDetails.plan?.subscription.planPricingVariantId
    );
    const nextPlan = pricingData.plans.find((p) =>
      p.pricingVariants.some((v) => v.id === nextVariantId)
    );
    const nextPlanVariant = nextPlan?.pricingVariants.findIndex(
      (v) => v.id === nextVariantId
    );
    return (
      <Popup
        show={showSwitchPlanPopup}
        closeCallback={() => {
          setShowSwitchPlanPopup(false);
        }}
        className="switch-plan-popup-wrapper"
      >
        <div className="switch-plan-popup">
          <div className="switch-plan-popup-header">
            <span>Current Plan</span>
            <svg
              className="arrow"
              width="111"
              height="27"
              viewBox="0 0 111 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M68.2396 7.83206C71.1254 7.17304 77.2449 7.78657 82.4579 10.2425C87.1427 12.4491 91.4171 15.9211 95.1247 19.5378C92.6427 18.9292 92.273 18.9144 89.9738 18.6285C87.6384 18.3379 86.4325 22.0609 88.2396 22.5093C90.6699 23.1125 96.0695 24.5258 99.0737 25.4497C99.8751 25.6963 101.655 26.718 102.897 24.8213C103.437 23.9957 103.716 22.9089 103.039 22.179C102.856 21.7725 99.2888 13.1597 98.6222 10.6835C98.2869 9.43736 96.3194 10.6905 96.6574 11.9474C97.0816 13.523 97.3951 15.1298 97.7311 16.7298C90.8792 10.1668 81.7516 4.2621 72.2769 5.25438C70.92 5.39634 69.5679 5.73903 68.2405 6.04195C67.3225 6.25123 66.9945 8.11739 68.24 7.83272L68.2396 7.83206Z"
                  fill="currentColor"
                ></path>
              </g>
            </svg>
            <span>New Plan</span>
          </div>
          <div className="switch-plan-popup-plans">
            <PricingTable
              websiteMode={websiteMode}
              embedMode={embedMode}
              activeVariantId={currentVariant || 0}
              pricingData={{
                ...pricingData,
                plans: pricingData.plans.filter(
                  (p) => p.planId === currentPlan?.planId
                ),
              }}
              extendedPricingData={pricingData}
              apps={apps}
              onLoading={onLoading}
              serviceName={serviceName}
              showDecorations={false}
              showCheckoutButton={false}
            />
            <PricingTable
              pricingData={{
                ...pricingData,
                plans: pricingData.plans.filter(
                  (p) => p.planId === nextPlan?.planId
                ),
              }}
              extendedPricingData={pricingData}
              activeVariantId={nextPlanVariant || 0}
              websiteMode={websiteMode}
              embedMode={embedMode}
              apps={apps}
              onLoading={onLoading}
              serviceName={serviceName}
              showDecorations={false}
              showCheckoutButton={false}
            />
          </div>
        </div>
        <div className="switch-plan-popup-footer">
          <Button
            color="transparent"
            onClick={async () => {
              updateSubscription(subscriptionId, nextVariantId);
            }}
            disabled={buttonLoading}
          >
            {buttonLoading ? "Updating..." : "Update Plan"}
          </Button>
        </div>
      </Popup>
    );
  }

  function renderCannotDowngradePopup() {
    if (!cannotDowngradePopupError) {
      return <></>;
    }

    return (
      <Popup
        show={!!cannotDowngradePopupError}
        closeCallback={() => {
          setCannotDowngradePopupError("");
        }}
        className="cannot-downgrade-popup-wrapper"
      >
        <h2>Change Plan</h2>
        <p>{cannotDowngradePopupError}</p>
        <div className="popup-footer">
          <Button
            color="transparent"
            onClick={async () => {
              setCannotDowngradePopupError("");
            }}
          >
            Close
          </Button>
          <Button
            color="transparent"
            className="go-to-dashboard-button"
            onClick={async () => {
              window.open("/dashboard", "_blank");
              setCannotDowngradePopupError("");
            }}
          >
            Open Dashboard
          </Button>
        </div>
      </Popup>
    );
  }

  function renderCancellationPopup(subscriptionId: string) {
    function getBundleName(): string {
      switch (serviceName) {
        case "bundles":
          return "All-in-One Bundle";
        case "minibundles":
          return "Mini Bundles";
        case "widgetsbundle":
          return "Widgets Bundle";
      }
      return "Bundle";
    }

    return (
      <Popup
        show={showCancellationPopup}
        closeCallback={() => {
          setShowCancellationPopup(false);
        }}
        className="cancellation-popup"
        style={{
          width: 630,
          border: "none",
        }}
      >
        <CommonNinjaWidget
          widgetId="9c8349fe-04e2-42d9-918d-e869e708761c"
          widgetProps={encodeURIComponent(
            JSON.stringify({
              // App name field
              "7e4c74d6-6fee-447b-bc47-51ab40d75287": !serviceName.includes(
                "bundle"
              )
                ? activeApp?.name
                : getBundleName(),
              // Email field
              "75e6a1e0-e8e4-4098-aa9b-983cf1d112e4": user?.email || "",
            })
          )}
        />
      </Popup>
    );
  }

  const currentVariant = plan.pricingVariants[activeVariantId];
  const subscriptionId =
    userDetails.plan?.subscription.platformSubscriptionId || "";
  const subscriptionStatus = userDetails.plan?.subscription.status || "";
  const isInTrial = subscriptionStatus === "trial";

  useEffect(() => {
    if (!subscriptionId) {
      return;
    }

    const messageHandler = async (event: MessageEvent) => {
      if (event.data && event.data.type === "cn-form-submitted") {
        // Handle the payload here if needed
        // const payload = event.data.payload || {};

        if (handlingCancellation.current) {
          return;
        }

        handlingCancellation.current = true;

        const success = await cancelSubscription(subscriptionId);

        if (success) {
          setShowCancellationPopup(false);
        }

        handlingCancellation.current = false;
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);

  if (!user?.isAuthenticated) {
    return (
      <Button
       className="amit-button"
        color="transparent"
        onClick={() => {
          const loginUrl = `${
            embedMode && !websiteMode ? "/embed" : ""
          }/signup?serviceName=${serviceName}`;
          let redirectUrl = `${
            embedMode ? "/embed" : ""
          }/pricing/${serviceName}?widgets=${
            plan?.features?.numberOfInstances
          }`;
          if (!websiteMode) {
            history.push(
              `${loginUrl}&redirectUrl=${encodeURIComponent(redirectUrl)}`
            );
            return;
          }

          // For website mode, we need to redirect to the pricing page for this plugin on website
          const pluginDetails = apps.find((p) => p.serviceName === serviceName);
          redirectUrl = `/widgets/${pluginDetails?.slug}/pricing`;
          if (window.top) {
            window.top.location.href = `${loginUrl}&redirectUrl=${encodeURIComponent(
              redirectUrl
            )}`;
          }
        }}
      >
        Sign Up
      </Button>
    );
  }

  // Users without active subscription
  if (!subscriptionId || (subscriptionStatus !== "active" && !isInTrial)) {
    // If it's a free plan - mark as current
    if (plan.isFree) {
      return (
        <Button
          color="transparent"
          disabled
          onClick={() => {
            return false;
          }}
        >
          Current Plan
        </Button>
      );
    }

    // Else, redirect to checkout page
    return (
      <Button
        className="amit-button"
        onClick={async () => {
          const platform = vendor === "shopify" ? vendor : "paddle";

          // plansService.reportFunnelEvent("PRICING_PLAN_SELECT");
          // plansService.reportFunnelEvent("PRICING_PLAN_SUBMIT");
          eventService.reportMixpanelEvent("Click select plan", {
            serviceName,
            authenticated: user.isAuthenticated,
            embedMode,
          });
          eventService.reportMixpanelEvent(`Click subscribe with ${platform}`, {
            serviceName,
            authenticated: user.isAuthenticated,
            embedMode: embedMode,
          });
          try {
            // @ts-ignore
            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            // @ts-ignore
            window.dataLayer.push({
              event: "begin_checkout",
              ecommerce: {
                currency: "USD",
                value: currentVariant.priceCount || 0,
                items: [
                  {
                    item_id: currentVariant.id,
                    item_name: `${plan.name}, ${currentVariant.name}`,
                    item_brand: vendor || 'nindo',
                    item_category: currentVariant.pricePeriod,
                    price: currentVariant.priceCount || 0,
                    quantity: 1 // plan.features?.numberOfInstances || 1,
                  },
                ],
              },
            });
            // @ts-ignore
            window.fbq?.('track', 'InitiateCheckout');
            // @ts-ignore
            window.lintrk?.('track', { conversion_id: 18793513 });

            posthog.capture("checkout_start", {
              currency: "USD",
              value: currentVariant?.priceCount || 0,
            });
          } catch (e) {}

          await redirectToCheckoutUrl(
            currentVariant.id,
            platform,
            currentVariant.priceCount,
            serviceName
          );
        }}
      >
        Select Plan
      </Button>
    );
  }

  // For users that have an active subscription
  // If it's the plan they already purchased
  const userPricingVariantId =
    userDetails.plan?.subscription.planPricingVariantId;

  if (
    (userPricingVariantId && userPricingVariantId === currentVariant?.id) ||
    (!userPricingVariantId && userDetails.plan?.planId === plan.planId) // Fallback for subscriptions without `planPricingVariantId`
  ) {
    return (
      <Button
        color="transparent"
        disabled
        onClick={() => {
          return false;
        }}
      >
        Current Plan
      </Button>
    );
  }

  // Else if, it's a free plan and cancel the subcription via api
  if (plan.isFree) {
    return (
      <>
        <Button
          color="transparent"
          onClick={() => {
            setShowCancellationPopup(true);
            // cancelSubscription(subscriptionId);
          }}
        >
          Cancel Plan
        </Button>
        {renderCancellationPopup(subscriptionId)}
      </>
    );
  }

  // Else, change to another plan via api
  return (
    <>
      <Button
        color="transparent"
        onClick={async () => {
          if (isInTrial) {
            toast.error(
              "You can't change plans during the trial period. Please cancel your subscription and select a new plan."
            );
            return;
          }
          plansService.reportFunnelEvent("PRICING_PLAN_CHANGE");
          if (vendor === "shopify") {
            // For shopify, we need to create a new subscription
            // there's no option to change plans on behalf of the user
            await redirectToCheckoutUrl(
              currentVariant.id,
              vendor,
              currentVariant.priceCount,
              serviceName
            );
            return;
          }
          if (serviceName === "widgetsbundle") {
            setShowSwitchPlanPopup(true);
          } else {
            updateSubscription(subscriptionId, currentVariant.id);
          }
        }}
      >
        Switch Plan
      </Button>
      {serviceName === "widgetsbundle" && (
        <>
          {renderSwitchPlanPopup(currentVariant.id, subscriptionId)}
          {renderCannotDowngradePopup()}
        </>
      )}
    </>
  );
};
